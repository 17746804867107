/* Diseño del card cuando ya hay alguien asignado a un turno, dia y terminal */

.elementPermisionarioTerminal{
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
}

.elementPermisionarioTerminal img{
    width: 55px;
    height: 55px;
    border-radius: 100%;
    object-fit: cover;
    margin-right: 5px;
}

.elementPermisionarioTerminal p{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 0.9em;
}

.elementPermisionarioTerminal svg{
    position:absolute;
    width: 15px;
    height: 15px;
    right: 0;
    top:0;
    cursor: pointer;
    color: var(--color-rojo)
}

.elementPermisionarioTerminal svg:hover{
    color:var(--color-negro)
}
