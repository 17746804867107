.itemSearchListResult img{
    width: 50px;
    height: 50px;
    border-radius: 100%;
}

.itemSearchListResult{
    border-bottom: 1px solid rgb(209, 209, 209);
    padding: 0.8em;
    transition: all 0.3s ease;
    color:var(--color-negro);
    background-color: rgb(250, 250, 250);
}

.itemSearchListResult:hover{
    background-color: rgb(243, 243, 243);
    cursor: pointer;
    color:var(--color-secundario)
}

.itemSearchListResult small{
    color:var(--color-negro);
}

.itemSearchListResult i{
    color:var(--color-secundario)
}