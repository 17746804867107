.image-container {
    position: relative;
    display: inline-block;
}

.image-checkbox {
    display: none;
}

.image-container img {
    width: 100%;
    cursor: pointer;
    border: 3px solid transparent;
    border-radius: 5px;
    box-sizing: border-box;
    transition: all 0.2s ease;
}

.image-selected {
    border-color: var(--color-primario);
    box-shadow: 0 0 5px rgba(0, 21, 255, 0.5);
    opacity: 0.8;
  }
  