#tablaChecador{
    display: flex;
    width: 100%;
    border-radius: 8px;
}

/* Columnas de los dias por turno */
.columnaDiasChecador{
    width: 25%;
    display: flex;
    flex-direction: column;
}
.columnaDiasChecador .elementoChecador{
    padding: 1em;
    height: 76px;
    display: flex;
    align-items: center;
    background-color: var(--color-secundario);
    color: var(--color-blanco);
}

.elementoChecador p{
    padding: 0;
}

/* Columnas del primer turno */
.columnaPrimerTurnoChecador{
    width: 50%;
    display: flex;
    flex-direction: column;
}

.columnaPrimerTurnoChecador .elementoChecador{
    padding: 0.6em;
    height: 76px;
    display: flex;
    align-items: center;
}

/* Columnas del segundo turno */
.columnaSegundoTurnoChecador{
    width: 50%;
    display: flex;
    flex-direction: column;
}

.columnaSegundoTurnoChecador .elementoChecador{
    padding: 0.6em;
    height: 76px;
    display: flex;
    align-items: center;
    border-left: 0.5px solid var(--color-gris);
}

/* Estilos del boton para agregar un nuevo horario en un turno */
.btnAgregarHorario{
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 1.5em;
    color: var(--color-secundario);
}

.btnAgregarHorario:hover{
    background-color: var(--color-complementario);
}