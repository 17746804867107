header{
    width: 100%;
    height: 80px;
    background-color: var(--color-primario);
    color: var(--color-blanco);
    position: fixed;
    top: 0;
    z-index: 200;
    display: flex;
    justify-content: space-between;
    padding: 25px;
}

.icon__menu{
    width: 11.875em;
    height: 100%;
    display: flex;
    align-items: center;
}

.icon__menu i{
    font-size:2em;
    cursor: pointer;
}

.icon__menu h1{
    font-size: 1.875em;
    cursor: pointer;
    font-family: var(--fuente-titulo);
}

.functions__menu{
    font-size: 1em;
    margin-right: 15.625em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: margin-right 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.search__menu{
    background-color: var(--color-blanco);
    width: 280px;
    height: 2.875em;
    border-radius: 1.875em;
    display: flex;
    align-items: center;
    margin-right: 1em;
    border: 1px solid var(--color-primario);
}

.search__menu:hover{
    border: 1px solid var(--color-negro);
}

.search__menu input{
    width: 100%;
    border-radius: 1.875em;
    border:none;
    padding-right:1em;
}

.search__menu input:focus{
    width: 100%;
    border-radius: 1.875em;
    border:none
}

.search__menu i{
    color:var(--color-negro);
    padding: 0 0.8em;
}

.profile__menu{
    display: flex;
    align-items: center;
}

.profile__menu p{
    margin-left: 1em;
    font-size: 0.875em;
    font-weight: regular;
}

.functions__menu-margin{
    margin-right: 80px!important;
}

#btnLogout{
    color:white
}

#btnLogout:hover{
    color:var(--color-negro)
}

@media screen and (max-width: 760px){

    .functions__menu{
        margin-right: 80px!important;
    }

    .functions__menu-margin{
        margin-right: 0px!important;
    }

}