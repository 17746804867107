/* CLASES PARA EL BUSCADOR DE LOS DIFERENTES MÓDULOS */

.buscadorInterno button {
    border: none;
    background: none;
    color: var(--color-gris);
}
/* styling of whole input container */
.buscadorInterno {
    --timing: 0.3s;
    --width-of-input: 20em;
    --height-of-input: 40px;
    --border-height: 2px;
    --input-bg: #fff;
    --border-color: var(--color-secundario);
    --border-radius: 30px;
    --after-border-radius: 1px;
    position: relative;
    width: var(--width-of-input);
    height: var(--height-of-input);
    display: flex;
    align-items: center;
    padding-inline: 0.8em;
    border-radius: var(--border-radius);
    transition: border-radius 0.5s ease;
    background: var(--input-bg,#fff);
}
/* styling of Input */
.inputSearch {
    font-size: 0.9rem;
    background-color: transparent;
    width: 100%;
    height: 100%;
    padding-inline: 0.5em;
    padding-block: 0.7em;
    border: none;
}
/* styling of animated border */
.buscadorInterno:before {
    content: "";
    position: absolute;
    background: var(--border-color);
    transform: scaleX(0);
    transform-origin: center;
    width: 100%;
    height: var(--border-height);
    left: 0;
    bottom: 0;
    border-radius: 1px;
    transition: transform var(--timing) ease;
}
/* Hover on Input */
.buscadorInterno:focus-within {
    border-radius: var(--after-border-radius);
}

.inputSearch:focus {
    outline: none;
}
.inputSearch:hover {
    border-color: transparent!important;
}
/* here is code of animated border */
.buscadorInterno:focus-within:before {
    transform: scale(1);
}
/* close button shown when typing */
.inputSearch:not(:placeholder-shown) ~ .reset {
    opacity: 1;
    visibility: visible;
}
/* sizing svg icons */
.buscadorInterno svg {
    width: 17px;
    margin-top: 3px;
}

/* ******************************* */