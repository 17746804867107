/* VIEW TRABAJADOR */

#fotoPerfilTrabajador{
    width: 230px;
    height: 230px;
    border-radius: 100%;
    object-fit: cover;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    background-color: var(--color-secundario-hover);
}

.nav-link{
    color: var(--color-negro)
}