@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

:root{
  --color-primario: #FF7925;
  --color-primario-hover: #F55E00;

  --color-secundario: #1A70C7;
  --color-secundario-hover: #135290;

  --color-terciario: #86C332;
  --color-terciario-hover: #659226;

  --color-complementario: #fafffd;
  --color-negro: #232323;
  --color-blanco: #fff;
  --color-gris: #342E37;
  --color-rojo:#D15253;
  --color-naranja-suave:#FBA772;
  --color-naranja-suave-hover:#F98F4D;

  --fuente-titulo: 'Bebas Neue';
  --fuente-principal: 'Poppins';
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Poppins';
}

/* Disable estilos de tabla bootstrap */
table {
  border-collapse: unset;
  border-spacing: 0;
}

textarea:focus, input:focus{
  outline: none;
}

h1, h2, h3, h4, h5{
  font-family: 'Bebas Neue';
}

p{
  margin-bottom:0!important;
}

a{
  text-decoration: none;
  cursor: pointer;
}

a:hover{
  text-decoration: none;
}

textarea{
  height:108px!important;
  padding-top: 10px!important;
}

label{
  font-size: 0.9em;
}

.hidden{
  display: none!important;
}

.btn-primario{
  background-color: var(--color-primario);
  color: var(--color-blanco);
}

.btn-primario:hover{
  background-color: var(--color-primario-hover);
  color: var(--color-blanco)
}

.btnPrimario{
  height: auto;
  width: auto;
  background-color: var(--color-blanco);
  color: var(--color-primario);
  border: none;
  padding: 1em 2em;
  text-align: center;
  border-radius: 0.625em;
  cursor: pointer;
  margin: 1em 0;
  font-size: 1em;
  transition: background-color 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  border: 1px solid var(--color-primario);
  box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
  
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnPrimario-lg{
  height: 110px;
  width: 100%;
  margin: 1.2em;
}

.btnPrimario:hover{
  background-color: var(--color-primario-hover);
  color: var(--color-blanco);
  border: 1px solid var(--color-primario);
}

.btnPrimario i{
  margin-right: 0.5em;
  font-size: 2.2em;
}

.btnPrimario-sm{
  font-size: 0.8em;
  padding: 0.5em 1.5em;
}

.btnPrimario-sm i{
  font-size: 1.4em;
}

.btnPrimario-colorSecundario{
  color: var(--color-secundario);
  border: 1px solid var(--color-secundario);
}

.btnPrimario-colorSecundario:hover{
  background-color: var(--color-secundario-hover);
  color: var(--color-blanco);
  border: 1px solid var(--color-secundario);
}

.btnPrimario-colorTerciario{
  color: var(--color-terciario);
  border: 1px solid var(--color-terciario);
}

.btnPrimario-colorTerciario:hover{
  background-color: var(--color-terciario-hover);
  color: var(--color-blanco);
  border: 1px solid var(--color-terciario);
}

hr{
  height: 1px;
  background-color: #D0D0D0;
  border: none;
  margin: 2em 0;
}

.noMargin{
  margin: 0 !important;
}

.noPadding{
  padding: 0!important;
}

.input{
  width: 100% !important;
  height: 2.875em;
  border: 1px solid #232323;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.02), 0px 1px 3px rgba(50, 50, 93, 0.15);
  border-radius: 0.375em;
  padding: 0em 0.75em;
  font-size: 0.9em;
  transition: all 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  margin:0 0 1em 0;
  background-color: var(--color-blanco);
}

.input::placeholder{
  font-size: 0.9em;
}

.input:focus{
  border-color: var(--color-secundario);
  -webkit-box-shadow: 0px 1px 11px -4px var(--color-secundario); 
  box-shadow: 0px 1px 11px -4px var(--color-secundario);
}

.btnFile{
  width: 100%;
  height: 4em;
  border: 1px solid var(--color-primario);
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.02), 0px 1px 3px rgba(50, 50, 93, 0.15);
  border-radius: 0.375em;
  padding: 0em 0.75em;
  font-size: 0.9em;
  transition: all 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  margin:1em 0;
  background-color: var(--color-primario);
  color: var(--color-blanco);
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnFile:hover{
  border-color: var(--color-primario-hover);
  -webkit-box-shadow: 0px 1px 11px -4px var(--color-primario-hover); 
  box-shadow: 0px 1px 11px -4px var(--color-primario-hover);
  background-color: var(--color-primario-hover);
  color: var(--color-blanco);
}

.subidaDocumentos__formulario label{
  width: 100%;
}

.flag{
  background-color: var(--color-terciario);
  border-radius: 100px;
  padding: 0.2em 1em;
  font-size: 11px;
  font-weight: bold;
  color: var(--color-blanco);
  cursor: default;
  max-width: 80px;
  text-align: center;
}

.flag-inactive{
  background-color: var(--color-rojo)!important;
}

/* CLASES PARA HACER QUE EL MODAL SALGA JUSTO EN EL CENTRO */
.modal-dialog {
  height: 100vh !important;
  display: flex;
}

.modal-content {
  margin: auto !important;
  height: fit-content !important;
}

/* ******************************************************** */

.bg-color-gris{
  background-color: var(--color-gris);
  color: var(--color-blanco);
}

.bg-color-naranja{
  background-color: var(--color-primario);
  color: var(--color-blanco);
}

.bg-color-azul{
  background-color: var(--color-secundario);
  color: var(--color-blanco);
}

.bg-color-verde{
  background-color: var(--color-terciario-hover);
  color: var(--color-blanco);
}

.bg-color-rojo{
  background-color: var(--color-rojo);
  color: var(--color-blanco);
}

.bg-color-amarillo{
  background-color: rgba(200, 150, 9, 0.3);
}

.color-gris{
  color: var(--color-gris);
}

.color-verde{
  color: var(--color-terciario-hover);
}

.color-rojo{
  color: var(--color-rojo);
}

.color-azul{
  color: var(--color-secundario);
}

.color-naranja{
  color: var(--color-primario);
}

.title-sm{
  font-size: 1.5em;
}

.underline{
  text-decoration: underline;
}

.font-size-lg{
  font-size: 1.1em;
}

/* ******************************************************** */

.btn-naranja{
  background-color: var(--color-primario);
  color: var(--color-blanco);
}

.btn-naranja:hover{
  background-color: var(--color-naranja-suave-hover);
}

/* LOADER */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--color-primario);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
