.permisionarios__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

/* AddPermisionario.js */

.perfil__fotoPerfil{
    border: 2px dashed rgb(229, 229, 229);
    padding: 1em;
    text-align: center;
    margin: 2em 0;
    display: flex;
    align-items: center;
}

.perfil__imagen{
    height:150px;
    width: 150px;
    object-fit: cover;
    border-radius: 100%;
}

.perfil__cambiarFoto{
    color:var(--color-azul);
    cursor: pointer;
    margin-left:2em;
    text-align: left;
}

.perfil__cambiarFoto:hover{
    color:var(--color-secundario-hover)
}

.dropdown-toggle{
    height:100%;
}

.selectpicker{
    padding:0!important
}

.bootstrap-select{
    padding:0!important;
}

.filter-option-inner, .filter-option{
    display: flex;
    align-items: center;
    font-size: 0.9em;
}

#btnAgregarClavePermisionario{
    width:100%;
    height: 2.875em;
    background-color:var(--color-naranja-suave);
    border-color: var(--color-naranja-suave);
    color: var(--color-negro);
    display: flex;
    justify-content: space-between;
    margin:0;
}

#btnAgregarClavePermisionario:hover{
    background-color: var(--color-naranja-suave-hover);
}

.previewPDF{
    width: 100%;
    height: 180px;
}