.globalCardsDeducibleProrrata{
    background-color: var(--color-gris);
    color: var(--color-blanco);
    padding: 1em;
    border-radius: 8px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin: 1em 0;
}

.cardDeducibleProrrata{
    min-width: 25%;
    border-left: 1px solid var(--color-gris-hover);
    padding-left:1em;
}

.cardDeducibleProrrata--no-border-left{
    border-left: none;
}