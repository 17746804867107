.background-image-logo{
    background-image: url('../../assets/images/fondo_ause_ia.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 92vh;
}

#dashboard{
    position:relative;
}

#dashboard::before{
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.5;
    background-image: url('../../assets/images/fondo_ause_ia.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 92vh;
}

#dashboard section{
    position:relative;
}