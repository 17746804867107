.dropdownFilter{
    font-size: 16px;
}

.dropdownFilter i{
    color: var(--color-secundario)
}

.dropdownFilter button.dropdown-button{
    border-style: dotted;
    border-radius: 1em;
    border-color: #c3c3c3;
    padding: 0.2em 0.5em;
    font-size: 0.8em;
    background-color: var(--color-blanco);
    transition: all 0.3s ease;
    color: #808080;
    font-weight: 500;
    margin: 10px 0;
}

.dropdownFilter button.dropdown-button:hover{
    background-color: #f4f4f4;
}

.dropdownFilter button.dropdown-button span{
    color:var(--color-primario)
}
.dropdownFilter button.dropdown-button i.fa-chevron-down{
    color: var(--color-primario);
}

.dropdownFilter .dropdown-menu{
    width: 300px;
    transition: all 0.3s ease-in-out;
    padding: 0.7em;
    font-size: 0.9em;
}

.dropdownFilter .dropdown-menu form{
    margin: 1em 0;
    font-size: 0.8em;
}