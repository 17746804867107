#accordionResultados .card .card-header{
    transition: all 0.3s ease;
}

#accordionResultados .card .card-header{
    background-color: white;
}

#accordionResultados .card .card-header h2 button{
    color:var(--color-negro)!important
}


#accordionResultados .card .card-header:hover{
    background-color: var(--color-primario);
}

#accordionResultados .card .card-header h2{
    font-family: var(--fuente-principal);
}

#accordionResultados .card .card-body{
    padding:0
}